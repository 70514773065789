import invariant from "invariant";
import { RealtimeConnection } from "./RealtimeConnection";
import { RealtimeSubscriptionCache } from "./RealtimeSubscriptionCache";

/**
 * Responsible for creating connections, and reconnecting when the connection drops.
 *
 * Invokes `onOpen` with a connection instance whenever a new connection is established.
 *
 * @example
 *
 *    import {RealtimeConnectionManager} from "@vimana/lib-realtime";
 *
 *    const manager = new RealtimeConnectionManager({
 *       getWebSocket: () => new WebSocket("wss://example.com/"),
 *       getCredentials: () => ({
 *         token: "abc123",
 *         plantName: "demo"
 *       }),
 *       onOpen: connection => connection.subscribe(["something", {id: 123}], {
 *          next: value => console.log(value),
 *          complete: () => console.log("done"),
 *          error: err => console.error(error)
 *       })
 *    });
 *
 */
export class RealtimeConnectionManager {
  constructor(config) {
    /**
     * Create a websocket connection.
     */
    this.getWebSocket = config.getWebSocket;

    /**
     * Returns the credentials used to authenticate a connection.
     */
    this.getCredentials = config.getCredentials;

    /**
     * Invoked when a connection opens.
     */
    this.onOpen = config.onOpen;

    /**
     * Invoked when a connection receives a command.
     */
    this.onCommand = config.onCommand;

    /**
     * Invoked when a connection closes normally.
     */
    this.onClose = config.onClose;

    /**
     * Invoked when a connection closes in error.
     */
    this.onError = config.onError;

    /**
     * The minimum number of milliseconds to wait before attempting
     * to reconnect a dropped connection.
     */
    this.minReconnectDelay = 250;

    /**
     * The maximum number of milliseconds to wait before attempting
     * to reconnect a dropped connection.
     */
    this.maxReconnectDelay = 30000;

    /**
     * The factor by which to increase the reconnection delay with each
     * failed retry. A factor of `1` would be no increase, `2` would double
     * the timeout, etc.
     */
    this.reconnectBackoffFactor = 1.5;

    /**
     * Whether to reconnect even if the connection closes normally.
     */
    this.reconnectOnClose = false;

    /**
     * The manager's subscription cache.
     */
    this.cache = null;

    /**
     * The active `RealtimeConnection` (if one has been created).
     * @private
     */
    this._activeConnection = undefined;

    /**
     * The number of milliseconds we waited before our last reconnection attempt.
     * @private
     */
    this._lastBackoff = 0;

    /**
     * The active reconnection timer.
     */
    this._reconnectionTimer = undefined;

    // Initialize from config
    if (typeof config.reconnectOnClose === "boolean") {
      this.reconnectOnClose = config.reconnectOnClose;
    }

    if (typeof config.minReconnectDelay === "number") {
      this.minReconnectDelay = config.minReconnectDelay;
    }
    if (typeof config.maxReconnectDelay === "number") {
      this.maxReconnectDelay = config.maxReconnectDelay;
    }
    if (typeof config.reconnectBackoffFactor === "number") {
      this.reconnectBackoffFactor = config.reconnectBackoffFactor;
    }

    if (config.cache instanceof RealtimeSubscriptionCache) {
      this.cache = config.cache;
    } else {
      this.cache = new RealtimeSubscriptionCache(config.cache || {});
    }
  }

  /**
   * Determine whether the manager has a connected, active connection.
   */
  get isConnected() {
    const { _activeConnection } = this;
    if (_activeConnection) {
      return _activeConnection.isConnected;
    }
    return false;
  }

  /**
   * Get a connection, creating one if necessary.
   */
  getConnection() {
    if (this._activeConnection) {
      return this._activeConnection;
    } else if (this._lastBackoff > 0) {
      // Cannot getConnection() while a reconnection attempt is in progress.
      // @todo should this be a warning or an error?
      return undefined;
    }
    return this.createConnection();
  }

  /**
   * Create a connection.
   */
  createConnection() {
    if (this._activeConnection) {
      throw new Error(
        "Cannot createConnection() when we already have an active connection."
      );
    }
    const { getWebSocket, getCredentials } = this;

    const credentials = getCredentials();

    this._activeConnection = new RealtimeConnection(getWebSocket(), {
      credentials,
      onOpen: this.handleConnectionOpen.bind(this),
      onCommand: this.handleConnectionCommand.bind(this),
      onClose: this.handleConnectionClose.bind(this),
      onError: this.handleConnectionError.bind(this),
    });
    return this._activeConnection;
  }

  /**
   * Dispose of the manager and disconnect any active connections.
   * Event handlers will not be invoked.
   */
  dispose() {
    this.onOpen = undefined;
    this.onCommand = undefined;
    this.onClose = undefined;
    this.onError = undefined;
    if (this._reconnectionTimer !== undefined) {
      clearTimeout(this._reconnectionTimer);
      this._reconnectionTimer = undefined;
    }
    if (this._activeConnection !== undefined) {
      this._activeConnection.close();
      this._activeConnection = undefined;
    }
  }

  /**
   * Invoked when a connection opens.
   */
  handleConnectionOpen(connection) {
    if (this._activeConnection !== connection) {
      // Ensure we set the active connection for scenarios where the
      // connection is opened synchronously (in tests).
      this._activeConnection = connection;
    }
    this.cache.onConnection(connection);
    invariant(connection, "activeConnection must be present after open.");
    this._lastBackoff = 0;
    if (this._reconnectionTimer !== undefined) {
      clearTimeout(this._reconnectionTimer);
      this._reconnectionTimer = undefined;
    }
    if (typeof this.onOpen === "function") {
      this.onOpen(connection);
    }
  }

  /**
   * Invoked when a connection receives a server-sent command.
   */
  handleConnectionCommand(commandName, payload) {
    if (typeof this.onCommand === "function") {
      this.onCommand(commandName, payload);
    }
  }

  /**
   * Invoked when a connection closes normally.
   */
  handleConnectionClose() {
    this._activeConnection = undefined;
    if (this.reconnectOnClose) {
      const {
        minReconnectDelay,
        maxReconnectDelay,
        reconnectBackoffFactor,
        _lastBackoff,
      } = this;

      const backoff = Math.min(
        Math.max(minReconnectDelay, _lastBackoff * reconnectBackoffFactor),
        maxReconnectDelay
      );

      this._lastBackoff = backoff;
      if (this._reconnectionTimer !== undefined) {
        clearTimeout(this._reconnectionTimer);
        this._reconnectionTimer = undefined;
      }
      this._reconnectionTimer = setTimeout(() => {
        this._reconnectionTimer = undefined;
        this.createConnection();
      }, backoff);
    }
    if (typeof this.onClose === "function") {
      this.onClose();
    }
  }

  /**
   * Invoked when a connection closes in error.
   */
  handleConnectionError(error) {
    this._activeConnection = undefined;
    if (typeof this.onError === "function") {
      this.onError(error);
    }

    const {
      minReconnectDelay,
      maxReconnectDelay,
      reconnectBackoffFactor,
      _lastBackoff,
    } = this;

    const backoff = Math.min(
      Math.max(minReconnectDelay, _lastBackoff * reconnectBackoffFactor),
      maxReconnectDelay
    );

    this._lastBackoff = backoff;
    if (this._reconnectionTimer !== undefined) {
      clearTimeout(this._reconnectionTimer);
      this._reconnectionTimer = undefined;
    }
    this._reconnectionTimer = setTimeout(() => {
      this._reconnectionTimer = undefined;
      this.createConnection();
    }, backoff);
  }
}
